const availableProps = [
  'computer',
  'windows',
  'linux',
  'mac',
  'mobile',
  'tablet',
  'android',
  'ios',
  'firefox',
  'chrome',
  'edge',
  'safari',
]

export default availableProps
